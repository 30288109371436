import ChevronDownIcon from "@heroicons/react/16/solid/ChevronDownIcon";
import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import SparklesIcon from "@heroicons/react/20/solid/SparklesIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import { styled as styling } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const Wrapper = styling("div")({
  backgroundColor: Color.backgroundGrey,
  minHeight: "100vh",
  height: "100%",
  margin: "4px 0 0",
  marginTop: "58px",

  //modified marginTop of joblist because there is a gap between beware of fraud banner and job list page
  //root cause is navbar refinement has different height in each breakpoint
  "@media (max-width: 1279px)": {
    marginTop: "74px",
  },

  "@media (max-width: 661px)": {
    marginTop: "94px",
  },

  "@media (min-width: 600px) and (max-width: 640px)": { 
    marginTop: "104px !important",
  },

  "@media (max-width: 640px)": {
    marginTop: "110px",
  },

  "@media (max-width: 640px)": {
    marginTop: "110px",
  },

  "@media (max-width: 358px)": {
    marginTop: "130px",
  },
});

export const TabNavWrapper = styling("div")((props) => ({
  position: "sticky",
  top: "calc(68px + 58px)",
  zIndex: "10",

  backgroundColor: Color.white,
  borderBottom: "2px solid " + Color.black,

  display: "flex",
  alignItems: "center",

  //modified this because there is a gap between jlp and beware of fraud banner
  //root cause is navbar refinement has different height in each breakpoint
  "@media (max-width: 1279px)": {
    padding: "0 1.5rem",
    top: "calc(52px + 86px)", //navbar(height) + banner(height)
  },

  "@media (max-width: 661px)": {
    padding: "0 1.5rem",
    top: "calc(52px + 107px)", //navbar(height) + banner(height)
  },

  "@media (min-width: 600px) and (max-width: 640px)": {
    padding: "0 1rem",
    top: "calc(60px + 108px) !important", //navbar(height) + banner(height)
  },

  "@media (max-width: 640px)": {
    padding: "0 1rem",
    top: "calc(60px + 108px)", //navbar(height) + banner(height)
  },

  "@media (max-width: 358px)": {
    padding: "0 1rem",
    top: "calc(60px + 128px)", //navbar(height) + banner(height)
  },
}));

export const TabWrapper = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",

  width: "100%",
  maxWidth: "1440px",
  padding: "0.75rem 5rem 0",
  margin: "0 auto -2px",

  "@media (max-width: 1279px)": {
    maxWidth: "916px",
    padding: "0",
    margin: "0 auto -2px",
  },
}));

export const TabGroup = styling("div")({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  gap: "0.375rem",

  height: "4.25rem",
  width: "100%",

  "@media (max-width: 1279px)": {
    height: "3.25rem",
  },

  "@media (max-width: 640px)": {
    justifyContent: "center",
  },
});

export const TabContainer = styling("div")((props) => ({
  position: "relative",

  display: "flex",
  alignItems: "center",

  height: "58px !important",
  width: "100%",
  maxWidth:
    props?.selectedTab === 1 && props.currentTab === 1
      ? "728px !important" //32px(paddingLeft+Right) + 700px(component inside tab)
      : props?.selectedTab === 0 && props.currentTab === 0
      ? "362px"
      : "160px",
  margin: "0 0",

  overflowX: "clip",

  cursor: "pointer",
  transition:
    "all 0.2s ease-in-out, color 0.1s ease-in-out, font-weight 0.1s ease-in-out, text-decoration 0.2s ease-in-out, max-width 0.1s ease-in-out",

  ...(props.$disabled && {
    cursor: "not-allowed",
    pointerEvents: "none",
  }),

  "@media (max-width: 1279px)": {
    justifyContent: "center",
    maxWidth: "160px",
    height: "44px",
  },
}));

export const TabContent = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",

  width: "100%",
  padding: "0.625rem 1rem",
  zIndex: 1,

  "@media (max-width: 1279px)": {
    justifyContent: "center",
    padding: "0.625rem 0.875rem 0.625rem 0.625rem",
  },
}));

export const TabTitle = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",

  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "nowrap",
  whiteSpace: "nowrap",

  transition: "all 0.05s ease-in-out",

  ...(props.selected && {
    color: Color.hiredlyPurple,
    fontWeight: "700",
    textDecoration: "none",
  }),

  "@media (min-width: 1280px)": {
    "&:hover": {
      fontWeight: "700",
      textDecoration: "underline",
    },
  },

  "@media (max-width: 1279px)": {
    fontSize: "0.875rem",
  },
}));

export const TabSlider = styling("div")((props) => ({
  position: "absolute",
  bottom: "0",

  borderRadius: "0.5rem 0.5rem 0 0",

  height: "100%",
  width: "0",

  transition: "all 0.15s ease-in-out, width 0.05s ease-in-out",

  ...(props.selected && {
    width: "100%",
    backgroundColor: Color.backgroundGrey,
    borderTop: "2px solid" + Color.black,
    borderLeft: "2px solid" + Color.black,
    borderRight: "2px solid" + Color.black,
  }),
}));

export const ForYouOptionsContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  width: "100%",
  margin: "0 0 0 1rem",

  "@media (max-width: 1279px)": {
    justifyContent: "space-between",

    maxWidth: "972px",
    padding: "1rem 1.5rem 0",
    margin: "0 auto",
  },

  "@media (max-width: 640px)": {
    padding: "1rem 1rem 0",
  },
}));

export const JobListFiltersContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  width: "100%",
  margin: "0 0 0 1rem",

  "@media (max-width: 1279px)": {
    justifyContent: "space-between",

    maxWidth: "972px",
    padding: "1rem 1.5rem 0",
    margin: "0 auto",
  },

  "@media (max-width: 640px)": {
    padding: "1rem 1rem 0",
  },
}));

export const ButtonStyled = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  color: props.selected ? "rgba(115, 95, 180, 1)" : Color.darkGrey,
  backgroundColor: props.selected ? Color.lightHiredlyPurple : Color.white,
  border: props.selected
    ? `1px solid rgba(115, 95, 180, 1)`
    : `1px solid ${Color.secondaryGrey}`,
  boxShadow: props.selected ? `0px 0px 0px 1px rgba(115, 95, 180, 1)` : "none",
  borderRadius: "0.5rem",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  letterSpacing: "0.15px",
  whiteSpace: "nowrap",

  height: "2.25rem",
  padding: "0 0.625rem",

  transition:
    "all 0.15s ease-in-out, border 0.025s ease-in-out, box-shadow 0.175s ease-in-out",

  "@media (min-width: 1280px)": {
    "&:hover": {
      color: props.selected ? Color.darkPurple : Color.black,
      backgroundColor: props.selected ? "#EFEFFD" : Color.white,
      border: props.selected ? "1px solid #EFEFFD" : `1px solid ${Color.white}`,
      boxShadow: props.selected
        ? "0px 0px 0px 2px" + Color.darkPurple
        : "0px 0px 0px 1.5px rgba(0, 0, 0, 0.68)",

      "* > .MuiSwitch-track": {
        backgroundColor: "rgba(0, 0, 0, 0.68)",
      },
    },
  },

  "@media (max-width: 1279px)": {
    height: "2.5rem",
  },

  "@media (max-width: 640px)": {
    flexGrow: "1",
    height: "2.25rem",
    minWidth: "fit-content",
    width: "50%",
  },
}));

export const TextStyled = styling(Typography)({});

//Tooltip Component//
export const TooltipStyled = styling(Tooltip)({});

// Select Component //
export const SelectContainer = styling("div")({
  width: "100%",
  maxWidth: "160px",

  "@media (max-width: 640px)": {
    maxWidth: "100%",
  },
});

export const SelectHeader = styling(Box)((props) => ({
  color: "rgba(0, 0, 0, 0.38)",
  backgroundColor: Color.white,
  border: `1px solid ${Color.grey}`,
  borderRadius: "0.5rem",

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.5rem",
  // flexWrap: "nowrap",

  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  whiteSpace: "nowrap",

  padding: "0.5rem 0.625rem",

  transition: "all 0.15s ease-in-out",

  ...(props.active && {
    color: Color.black,
    border: `1px solid ${Color.hiredlyPurple}`,
  }),

  ...(props.$disabled && {
    pointerEvents: "none",
    opacity: "0.5",
    cursor: "not-allowed",
  }),

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    height: "2.25rem",
  },
}));

export const SelectDropdown = styling("div")((props) => ({
  position: "absolute",
  zIndex: "10",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  backgroundColor: Color.white,
  border: "2px solid transparent",
  borderRadius: "0.5rem",

  width: "100%",
  maxWidth: "160px",
  margin: "0.25rem 0 0 0",

  opacity: "0",
  pointerEvents: "none",
  transition:
    "max-height 0.2s ease-in, border 0.15s ease-in-out, opacity 0.15s ease-in-out, margin-top 0.15s ease-in-out",
  transform: "translate3d(0,0,0)",

  ...(props.open && {
    border: "2px solid" + Color.black,
    pointerEvents: "auto",
    opacity: "1",
  }),

  "@media (max-width: 640px)": {
    flexGrow: "1",
  },
}));

export const SelectOption = styling(Button)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "0.875rem",

  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  textTransform: "initial",
  textAlign: "left",
  textWrap: "nowrap",

  height: "2.25rem",
  width: "100%",

  padding: "0 1rem",

  "&:hover": {
    backgroundColor: Color.lightHiredlyPurple,
  },
}));

export const FypIcon = styling(SparklesIcon)({
  height: "20px",
  width: "20px",
});

export const SearchIcon = styling(MagnifyingGlassIcon)({
  height: "20px",
  width: "20px",
});

export const HistoryIcon = styling(ClockIcon)({
  height: "20px",
  width: "20px",
});

export const InfoIcon = styling(InformationCircleIcon)({
  height: "16px",
  width: "16px",
});

export const DownArrowIcon = styling(ChevronDownIcon)((props) => ({
  color: props.active ? Color.hiredlyPurple : Color.darkGrey,

  height: "20px",
  width: "20px",

  margin: "0 -4px 0 0",

  transform: props.selected ? "rotate(-180deg)" : "rotate(0deg)",
  transition: "all 0.15s ease-in-out",
}));
